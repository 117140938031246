/////////////////////////////////////////
#XrayTable.handsontable input[type="radio"][readonly] {
  pointer-events: none;
}
#XrayTable.handsontable td.pointer {
  cursor: pointer;
}

#XrayTable.handsontable span.colHeader {
  pointer-events: auto;
}
#XrayTable.handsontable td a {
  color: #000;
  text-decoration: none;
}
#XrayTable.handsontable td a:hover {
  text-decoration: underline;
}
#XrayTable.handsontable th, #XrayTable.handsontable td {
  white-space:nowrap !important;
  height: 16px !important;
  line-height: 15px !important;
}

// saleslist 左上ヘッダーの下線を消す
#XrayTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// saleslist 列ヘッダーの背景色デフォルト
#XrayTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// saleslist 列ヘッダー背景色
#XrayTable.handsontable THEAD TH.saleslist_H {
  background: #595959;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_J {
  background: #5D44A2;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_JP {
  background: #A66BD3;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_K {
  background: #a7386b;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_KP {
  background: #cf698d;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_KR {
  background: #9999FF;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_P {
  background: #698DCF;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_B {
  background: #70AD47;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_Z {
  background: #92D050;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_C1 {
  background: #963634;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_C2 {
  background: #C0504D;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_HK {
  background: #E26B0A;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_I {
  background: #76933C;
  color: white;
}
#XrayTable.handsontable THEAD TH.saleslist_ID {
  background: #9BBB59;
  color: white;
}
#XrayTable.handsontable .saleslist_border_double {
  border-right: 3px double #ccc;
}
// saleslist 行背景色
#XrayTable.handsontable .saleslist_row_total {
  background: #CCFFFF;
}
//saleslist 行背景色 Lv2
#XrayTable.handsontable .saleslist_row_lv2 {
  background: #E3F0DA;
}
//saleslist 行背景色 Lv3
#XrayTable.handsontable .saleslist_row_lv3 {
  background: #C7E1B5;
}
//saleslist 行背景色 Lv4
#XrayTable.handsontable .saleslist_row_lv4 {
  background: #A9D08E;
}
//saleslist 行背景色 Lv5
#XrayTable.handsontable .saleslist_row_lv5 {
  background: #8DC169;
}
//saleslist 行背景色 Lv6
.handsontable .saleslist_row_lv6 {
  background: #71B244;
}
//saleslist 行背景色 Lv7
.handsontable .saleslist_row_lv7 {
  background: #55A31F;
}
//saleslist 行背景色 Lv8
.handsontable .saleslist_row_lv8 {
  background: #4DA30F;
}
//saleslist 行背景色 Lv2 選択時
#XrayTable.handsontable .saleslist_row_lv2.currentRow, 
#XrayTable.handsontable .saleslist_row_lv2.area, 
#XrayTable.handsontable .saleslist_row_lv2.current {
  background: #DAE3F3;
}
//saleslist 行背景色 Lv3 選択時
#XrayTable.handsontable .saleslist_row_lv3.currentRow,
#XrayTable.handsontable .saleslist_row_lv3.area,
#XrayTable.handsontable .saleslist_row_lv3.current {
  background: #B5C7E7;
}
//saleslist 行背景色 Lv4 選択時
#XrayTable.handsontable .saleslist_row_lv4.currentRow, 
#XrayTable.handsontable .saleslist_row_lv4.area,
#XrayTable.handsontable .saleslist_row_lv4.current {
  background: #8EA9DB;
}
//saleslist 行背景色 Lv5 選択時
#XrayTable.handsontable .saleslist_row_lv5.currentRow, 
#XrayTable.handsontable .saleslist_row_lv5.area,
#XrayTable.handsontable .saleslist_row_lv5.current {
  background: #698DCF;
}
//saleslist 行背景色 Lv6 選択時
#XrayTable.handsontable .saleslist_row_lv6.currentRow, 
#XrayTable.handsontable .saleslist_row_lv6.area,
#XrayTable.handsontable .saleslist_row_lv6.current {
  background: #4471C3;  
}
//saleslist 行背景色 Lv7 選択時
#XrayTable.handsontable .saleslist_row_lv7.currentRow,
#XrayTable.handsontable .saleslist_row_lv7.area,
#XrayTable.handsontable .saleslist_row_lv7.current {
  background: #1F55B7;
}
//saleslist 行背景色 Lv8 選択時
#XrayTable.handsontable .saleslist_row_lv8.currentRow,
#XrayTable.handsontable .saleslist_row_lv8.area,
#XrayTable.handsontable .saleslist_row_lv8.current {
  background: #114DBD;
}

// saleslist セル文字色
#XrayTable.handsontable .saleslist_cell_fg_gray {
  color: #BBB !important;
}
#XrayTable.handsontable .saleslist_cell_fg_red {
  color: #F00 !important;
}
#XrayTable.handsontable .saleslist_cell_fg_blue {
  color: #7491CD !important;
}
#XrayTable.handsontable .saleslist_cell_rate_under_90 {
  background: #FFCCFF !important;
}
#XrayTable.handsontable .saleslist_cell_rate_under_100 {
  background: #FFFF66 !important;
}
#XrayTable.handsontable .saleslist_cell_rate_over_110 {
  background: #8DB4E2 !important;
}
#XrayTable.handsontable .saleslist_cell_nebiki_rate_under_90 {
  background: #8DB4E2 !important;
}
#XrayTable.handsontable .saleslist_cell_nebiki_rate_over_200 {
  background: #000 !important;
  color:white !important;
}
#XrayTable.handsontable .saleslist_cell_nebiki_rate_over_110 {
  background: #FFCCFF !important;
}
#XrayTable.handsontable .saleslist_cell_nebiki_rate_over_100 {
  background: #FFFF66 !important;
}
#XrayTable.handsontable .saleslist_cell_pb_rate_over_10 {
  background: #8DB4E2 !important;
}
#XrayTable.handsontable .saleslist_cell_pb_rate_over_07 {
  background: #B2DE84 !important;
}
#XrayTable.handsontable .saleslist_cell_pb_rate_under_03 {
  background: #FFCCFF !important;
}

#XrayTable.handsontable .saleslist_cell_keppin_rate_over_5 {
  background: #000 !important;
  color:white !important;
}
#XrayTable.handsontable .saleslist_cell_keppin_rate_over_3 {
  background: #FFCCFF !important;
}
#XrayTable.handsontable .saleslist_cell_keppin_rate_over_1 {
  background: #FFFF66 !important;
}
#XrayTable.handsontable .saleslist_cell_keppin_rate_diff_over_1 {
  background: #FFCCFF !important;
}

#XrayTable.handsontable .saleslist_cell_zaiko_rate_black {
  background: #000 !important;
  color:white !important;
}
#XrayTable.handsontable .saleslist_cell_zaiko_rate_pink {
  background: #FFCCFF !important;
}
#XrayTable.handsontable .saleslist_cell_zaiko_rate_yellow {
  background: #FFFF66 !important;
}

#XrayTable.handsontable .saleslist_cell_center_rate_over_07 {
  background: #000 !important;
  color:white !important;
}
#XrayTable.handsontable .saleslist_cell_center_rate_over_06 {
  background: #FFCCFF !important;
}
#XrayTable.handsontable .saleslist_cell_center_rate_over_058 {
  background: #FFFF66 !important;
}

#XrayTable.handsontable .saleslist_cell_hankan_rate_over_10 {
  background: #000 !important;
  color:white !important;
}
#XrayTable.handsontable .saleslist_cell_hankan_rate_over_08 {
  background: #FFCCFF !important;
}
#XrayTable.handsontable .saleslist_cell_hankan_rate_over_07 {
  background: #FFFF66 !important;
}

// コメントセル（通常コメントは非表示）
#XrayTable.handsontable .htCommentCell:after {
  border-top: none;
  border-left: none;
}

// 左上ヘッダーの下線を消す
#Xray1KeppinRankingTableGroup2.handsontable thead tr th:first-child {
  border-bottom: none
}
// 列ヘッダーの背景色デフォルト
#Xray1KeppinRankingTableGroup2.handsontable thead th {
  background: #386ba7;
  color: white;
}
// 列ヘッダー背景色
#Xray1KeppinRankingTableGroup2.handsontable THEAD TH.saleslist_H {
  background: #595959;
  color: white;
}
#Xray1KeppinRankingTableGroup2.handsontable THEAD TH.saleslist_K {
  background: #a7386b;
  color: white;
}

#Xray1KeppinRankingTableGroup2.handsontable .keppin_rate_over_5 {
  background: #000 !important;
  color:white !important;
}
#Xray1KeppinRankingTableGroup2.handsontable .keppin_rate_over_3 {
  background: #FFCCFF !important;
}
#Xray1KeppinRankingTableGroup2.handsontable .keppin_rate_over_1 {
  background: #FFFF66 !important;
}
#Xray1KeppinRankingTableGroup2.handsontable .keppin_rate_diff_over_1 {
  background: #FFCCFF !important;
}

//センター
// 左上ヘッダーの下線を消す
#Xray1KeppinRankingTableCenter.handsontable thead tr th:first-child {
  border-bottom: none
}
// 列ヘッダーの背景色デフォルト
#Xray1KeppinRankingTableCenter.handsontable thead th {
  background: #386ba7;
  color: white;
}
// 列ヘッダー背景色
#Xray1KeppinRankingTableCenter.handsontable THEAD TH.saleslist_H {
  background: #595959;
  color: white;
}
#Xray1KeppinRankingTableCenter.handsontable THEAD TH.saleslist_K {
  background: #a7386b;
  color: white;
}

#Xray1KeppinRankingTableCenter.handsontable .keppin_rate_over_5 {
  background: #000 !important;
  color:white !important;
}
#Xray1KeppinRankingTableCenter.handsontable .keppin_rate_over_3 {
  background: #FFCCFF !important;
}
#Xray1KeppinRankingTableCenter.handsontable .keppin_rate_over_1 {
  background: #FFFF66 !important;
}
#Xray1KeppinRankingTableCenter.handsontable .keppin_rate_diff_over_1 {
  background: #FFCCFF !important;
}
